@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .centered-items {
    @apply flex items-center justify-center
  }

  .h-cursor-p {
    @apply hover:cursor-pointer
  }
}

@layer base {
  :root {
    --body_background: white;
    --body_color: #f7f9fa;
    --border_color: #f7f1f1;
    --leftmenu_textcolor: #7b8191;
    --first_titlecolor: #383853;
    --second_titlecolor: #4a4a69;
    --leftmenu_iconscolor:#7b8191;
    --card_background:#ffffff; 
    
    
  }

  [class="dark"] {
    --body_background: #2a2e3f;
    --body_color: #383d52;
    --border_color: #4d4c69;
    --leftmenu_textcolor: #a9abbd;
    --first_titlecolor: #fcfcfc;
    --second_titlecolor: #eaecf3;
    --leftmenu_iconscolor:#bfc8de;
    --card_background: #2a2e3f;
  }

}

div.connectionContent::-webkit-scrollbar {
  width: 8px;
}
 
div.connectionContent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
 
div.connectionContent::-webkit-scrollbar-thumb {
  background-color: rgb(230, 228, 228);
}

[class="dark"] div.connectionContent::-webkit-scrollbar-thumb {
  background-color: #1c1d22
}

/* loadingScreen */

.loading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}

.loading-fill{
  background-color: #565656;
  text-transform: uppercase;
  position: relative;
  background-clip: text;
  -webkit-background-clip: text;
  color:transparent;
}

.loading-fill::before {
  content: attr(data-title);
  background: #42aff3;
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 0;
  animation-name: test-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background-clip: text;
  -webkit-background-clip: text;
  color:transparent;
}

@keyframes test-animation {
  from {
      width:0px;
  }

  to {
      width:100%;
  }
}
